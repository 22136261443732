<!--  -->
<template>
  <div>
    <div class="cardQuqryW" v-for="(item, index) in filterList" :key="index">
      <div class="cardQuqryW_title">{{item.label}}</div>
      <div class="cardQuqryW_cont">
        <div :class="['cardQuqryW_cont_tag', param[item.key] === itemy.value ? 'active' : '']" v-for="(itemy,indey) in item.list" :key="indey"  @click="paramSelect(item.key, index, indey)">{{itemy.name}}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SelectRows',
  data () {
    return {
      param: this.initParam(),
    };
  },
  props: {
    filterList:{
      type: Array,
      default:()=>[],
      required: true
    },
    type:{
      type: String,
      default: 'park'
    },
  },

  methods: {
    initParam () {
      return {
        baseName: '',
        rent: '',
        rentMin: '',
        rentMax: '',
        buildingType: '',
        buildingStructure: '',
        standardArea: '',
        standardAreaMin: '',
        standardAreaMax: '',
        floorHeight: '', 
        floorHeightMin: '', 
        floorHeightMax: '', 
        standardFloorLoad: '', 
        standardFloorLoadMin: '', 
        standardFloorLoadMax: '', 
        operateYear: '',
        operateYearMin: '',
        operateYearMax: '',
      }
    },
    paramSelect(key, parentIndex, childIndex) {
      const {list, range} = this.filterList[parentIndex]
      console.log(list);
      const item = list[childIndex]
      if(this.param[key] === item.value) {
        this.param[key] = ''
        if (range) {
          this.param[`${key}Min`] = this.param[`${key}Max`] = ''
        }
      } else {
        this.param[key] = item.value
        if (range) {
          this.param[`${key}Min`] = item.extend1 || ''
          this.param[`${key}Max`] = item.extend2 || ''
        }
      }
      this.$emit('change', this.param)
    },
    clear() {
      this.param = this.initParam()
      this.$emit('change', this.param)
    }
  }
}

</script>
<style lang='scss' scoped>
.cardQuqryW{
  font-size: 20px;
  color: #333333;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  
  &_title{
    width: 130px;
    font-size: 18px;
    font-weight: bold;
    margin-right: 20px;
    margin-top: 16px;
    
  }
    &_cont{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: fit-content;

    &_tag{
    cursor: pointer;
    text-align: center;
    font-size: 18px;
    padding: 7px;
    border: 1px solid #E2E2E2;
    min-width: 152px;
    height: 38px;
    border-radius: 7px;
    margin:10px;
    box-sizing: border-box;
    position: relative;
      &:hover,
      &.active {
        color: #06BDE1;
        border: 1px solid #c6e2ff;
        // background: rgba(0, 113, 255,.12);
        background: #ecf5ff;

      }
    }
  }
}
</style>
