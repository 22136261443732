<template>
  <div>
    <SearchInputTab ref="searchRef" @searchChange="searchChange" @tabChange="tabChange" />
    <div class="roomQueryW">
      <div class="roomQueryCont">
        <div class="roomQuery">
          <div class="roomQuery_title">
            <div>请选择筛选条件</div>
            <el-button class="btn" @click="emptyParams">清空筛选</el-button>
          </div>
          <SelectRows
            ref="queryRows"
            class="roomQuery_sub"
            :filterList="isPark ? parkFilterList : buildingFilterList"
            @change="paramsChange"
          />
        </div>
      </div>

      <div class="roomQueryMiddle">
        <div class="roomQueryMiddle_txt">
        共找到 <span>{{ total }}</span> {{isPark ? '个' : '栋'}}符合条件的{{isPark ? '园区' : '楼宇'}}
        </div>

        <div class="roomQueryMiddle_body" v-if="total > 0">
          <ProjectCard
            class="roomQueryMiddle_body_sub"
            v-for="(item, index) in list"
            :type="tabKey"
            :info="item"
            :key="index"
            @go="goDetail(item.id)"
          />
        </div>

        <div v-else class="noData">暂无相关内容!</div>

        <div class="roomQueryMiddle_pageination" v-if="total">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="params.pageSize"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>

      <div class="topBtn" @click="backTop"></div>
    </div>
  </div>
</template>

<script>
import SelectRows from "@/components/SelectRows.vue";
import ProjectCard from "@/components/ProjectCard.vue";
import SearchInputTab from '@/components/SearchInputTab.vue';
import {
  getDicts, 
  getProjList,
  getProjPoster,
  getBuildingList,
  getBuildingPoster
} from "@/api/index";
import { mapState } from "vuex";

export default {
  name: "ProjList",
  components: {
    SelectRows,
    ProjectCard,
    SearchInputTab,
  },
  data() {
    return {
      buildingFilterList: [],
      parkFilterList: [],
      params: {
        pageNo: 1,
        pageSize: 10,
      },
      list: [],
      total: 0,
    };
  },
  mounted() {
    this.searchDicts()
    this.search()
  },
  computed: {
    ...mapState(['tabKey']),
    isPark() {
      return this.tabKey === 'park'
    }
  },
  methods: {
    backTop() {
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },
    searchDicts() {
        getDicts({
            keys: 'creProject.baseName,creBuilding.buildingType,form.rentPrice,creBuilding.buildingStructure,form.operateYear'
        }).then(res => {
          let data = res.data
          const defaultArrEle = [
            // {name: '不限', value: ''}
          ]
          const baseNameList= data['creProject.baseName'].concat(defaultArrEle),
              buildingTypeList= data['creBuilding.buildingType'].concat(defaultArrEle),
              rentList= data['form.rentPrice'].concat(defaultArrEle),
              buildingStructureList= data['creBuilding.buildingStructure'].concat(defaultArrEle),
              // floorHeightList= data['form.floorHeight'].concat(defaultArrEle),
              // standardAreaList= data['form.standardArea'].concat(defaultArrEle),
              // standardFloorLoadList= data['form.standardFloorLoad'].concat(defaultArrEle),
              operateYearList= data['form.operateYear'].concat(defaultArrEle)
          const buFilterList = [
              {
                  label: '物业类型',
                  key: 'buildingType',
                  range: false,
                  list: buildingTypeList
              },
              {
                  label: '租金',
                  key: 'rent',
                  range: true,
                  list: rentList
              },
              {
                  label: '结构形式',
                  key: 'buildingStructure',
                  range: false,
                  list: buildingStructureList
              },
              // {
              //     label: '标准层高(m)',
              //     key: 'floorHeight',
              //     range: true,
              //     list: floorHeightList
              // },
              // {
              //     label: '标准层面积(㎡)',
              //     key: 'standardArea',
              //     range: true,
              //     list: standardAreaList
              // },
              // {
              //     label: '标准层载荷',
              //     key: 'standardFloorLoad',
              //     range: true,
              //     list: standardFloorLoadList
              // },
              {
                  label: '交付年限',
                  key: 'operateYear',
                  range: true,
                  list: operateYearList
              }
          ]
          const parkFilterList = 
            {
                label: '所属区域',
                key: 'baseName',
                list: baseNameList
            }
          this.buildingFilterList = [parkFilterList, ...buFilterList]
          this.parkFilterList = [parkFilterList]
        })
    },
    tabChange() {
      this.params.pageNo = 1
      this.search()
    },
    goDetail(id) {
      this.$router.push({ name: 'ProjectDetail', query: { id, type: this.tabKey} });
    },
    searchChange(val) {
      this.params.search = val
      this.params.pageNo = 1
      this.search()
    },
    search() {
      let api = this.isPark ? getProjList : getBuildingList
      let imgApi = this.isPark ? getProjPoster : getBuildingPoster
      api(this.params).then(res => {
        let {records, total} = res.data
        records = records.map(rec => ({...rec, imgUrl: imgApi(rec.id), totalAreaWan: rec.totalArea ? (rec.totalArea / 10000).toFixed(2): '-'}))
        this.list = records
        this.total = total
      })
    },
    paramsChange(param) {
      this.params = {
        ...this.params,
        ...param,
        pageNo: 1
      }
      this.search()
    },
    emptyParams() {
      this.params.search = ''
      this.params.pageNo = 1
      this.$refs.queryRows.clear()
      this.$refs.searchRef.clear()
    },
    currentChange(val) {
      this.params.pageNo = val
      this.search()
    }
   },
  
};
</script>

<style lang="scss">
.roomQueryMiddle_pageination {
  .el-pagination.is-background .el-pager li:not(.disabled) {
    &:hover {
      color: #06BDE1;
    }
    &.active {
      background-color: #06BDE1;
      &:hover {
        color: #fff;
      }
      
    }
  }
}

.roomQuery .btn.el-button {
  &:focus,
  &:hover {
    color: #06BDE1;
  }
}
</style>
<style lang="scss" scoped>
@import "@/assets/css/variable";

.noData {
  text-align: center;
  padding: 110px 0;
  font-size: 20px;
  color: #8d8d8d;
}
.topBtn {
  width: 60px;
  height: 60px;
  background: url("../assets/images/top.png") no-repeat center;
  background-size: contain;
  position: fixed;
  bottom: 3%;
  right: 3%;
  cursor: pointer;
}
.roomQueryW {
  width: 100%;
  overflow: hidden;
  margin: auto;
  position: relative;
  background: $background-color;
  z-index: 103;

  .roomQueryCont {
    width: 100%;
    background: white;

    padding-top: 40px;
    padding-bottom: 35px;
  }
  .roomQuery {
    width: $page-width;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
    &_title {
      font-size: 20px;
      color: #333333;
      border-bottom: 1px dashed #d5d6d6;
      padding-bottom: 15px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }
  }
  .roomQueryMiddle {
    width: $page-width;
    margin: auto;
    padding: 32px 20px 86px;
    background: $background-color;
    &_pageination {
      display: flex;
      justify-content: center;
      margin-top: 60px;
    }
    &_body {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      &_sub {
        margin: 0 0 20px;
      }
    }

    &_txt {
      font-size: $font_medium;
      color: #10406b;
      font-weight: bold;
      margin: 20px 0;
      span {
        color: #DF9C41;
      }
    }
  }
}
</style>
