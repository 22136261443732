<!--  -->
<template>
  <transition :css="false">
    <div class="header-search" ref="header">
      <div class="middle" ref="middle">
        <img class="middle_title" src="../assets/images/homeTitle.png" alt="" />
        <div class="middle_search">
          <input
            class="middle_search_input"
            autocomplete="off"
            placeholder="请输入项目名称"
            type="text"
            v-model="search"
          />
          <div class="middle_search_line"></div>
          <div class="searchBtn" @click="searchChange">
            <img
              class="searchBtn_icon"
              src="../assets/images/searchIcon.png"
              alt=""
            />
            <div class="searchBtn_txt">搜索</div>
          </div>
        </div>
      </div>

      <transition :css="false">
        <div class="bottom" ref="bottomCont">
          <div :class="['bottom_info', tabKey === item.key ? 'active' : '']" ref="btn2" v-for="(item, index) in boxList" :key="index" @click="changeTab(item.key)">
            <div class="sub">
              <div :class="['bottom_img', item.key]"></div>
              <div class="bottom_txt">{{item.label}}</div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SearchInputTab",
  data() {
    return {
      boxList: [
        {
          key: 'park',
          label: '园区查询'
        },
        {
          key: 'building',
          label: '楼宇查询'
        },
      ],
      search: '',
    };
  },
  computed: {
    ...mapState(['tabKey'])
  },
  onMounted() {
    console.log(this.$route);
  },
  methods: {
    changeTab(key) {
      this.$store.dispatch('setTabKey', key)
      this.$emit('tabChange', key)
    },
    searchChange() {
      this.$emit('searchChange', this.search)
    },
    clear() {
      this.search = ''
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variable";

.header-search {
  box-sizing: border-box;
  width: 100%;
  height: 516px;
  padding-top: 48px;
  background: url("../assets/images/homeBg.jpg") no-repeat center;
  background-size: cover;
  position: relative;


  .middle {
    text-align: center;
    margin: 0 auto;
    &_title {
      width: 626px;
    }
    &_search {
      width: 740px;
      height: 56px;
      background: rgba(255, 255, 255, 0.36);
      border-radius: 10px;
      margin: auto;
      margin-top: 55px;
      padding: 0 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;
      position: relative;
     
      &_input {
        width: 580px;
        height: 50px;
        border: none;
        margin-left: 20px;
        background: none;
        outline: none;
        color: white;
      }
      input::-webkit-input-placeholder {
        /* placeholder颜色  */
        color: #ededed;
        /* placeholder字体大小  */
        font-size: 18px;
        /* placeholder位置  */
      }

      &_line {
        width: 1px;
        height: 32px;
        background: white;
      }
      .mapBtn {
        width: 118px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        

        &_icon {
          width: 42px;
          height: 42px;
          margin-right: 5px;
        }
        &_txt {
        }
      }
      .searchBtn {
        width: 118px;
        height: 50px;
        background: linear-gradient(180deg, #01DEE4 -12.08%, rgba(0, 92, 176, 0.74) 121.62%);
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &_icon {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      }
    }
  }

  .bottom {
    margin: 55px auto 0;
    // position: absolute;
    // margin-top: 542px;
    // left: 0;
    // right: 0;
    display: flex;
    justify-content: center;
    .bottom_info {
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(17, 51, 95, 0.51);
      border: 1px solid rgba(17, 51, 95, 0.51);
      width: 310px;
      height: 144px;
      box-sizing: border-box;
      border-radius: 5px;
      font-size: 22px;
      text-align: center;
      position: relative;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 40px;
      }
      
      .sub {
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .bottom_img {
          width: 58px;
          height: 58px;
          margin-bottom: 13px;
          background: url("../assets/images/yuanqu-3x.png") no-repeat;
          background-size: 100% 100%;

            &.building {
            background-image: url("../assets/images/louyu-3x.png");
            }
        }
      }
      &.active,
      &:hover {
        font-weight: bold;
        border: 1px solid white;
        background: none;

        .bottom_img {
          background-image: url("../assets/images/yuanqu1-3x.png");
          &.building {
            background-image: url("../assets/images/louyu1-3x.png");
          }
        }
      }
    }
  }
}
</style>
