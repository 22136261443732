<!--  -->
<template>
  <div class="cardW" @click="detailPage">
       <div class="cardLeft">
         <el-image :src="info.imgUrl" fit="cover" lazy>
         </el-image>
       </div>
       <div class="cardRight">
            <div class="cardRight_subN_title">{{info.projectName}}<span v-if="type === 'building'">-{{info.buildingName}}</span></div>
           
            <div class="cardRight_sub1 left">
                <div class="cardRight-land-tag">{{info.baseName}}</div>
                <div class="cardRight-land-tag type" v-if="type === 'building' && info.buildingType">{{info.buildingType}}</div>
           </div>
            <div class="cardRight_sub1" v-if="type === 'park'">
                <div class="cardRight_sub1_tag1">总建筑面积:</div>
                <div class="cardRight_sub1_tag2"><span class="redAcite">{{info.totalAreaWan || '-'}}</span>万㎡</div>
           </div>
            <div class="cardRight_sub1" v-else>
                <div class="cardRight_sub1_tag1">租金:</div>
                <div class="cardRight_sub1_tag2"><span class="redAcite">{{info.rentPrice || '-'}}</span>元/㎡/月</div>
           </div>
       </div>
  </div>
</template>

<script>

export default {
    name: 'ProjectCard',
  data () {
    return {
    };
  },
  props: {
    type:{
        type: String,
        default: 'park'
    },
    info: {
        type: Object,
        default: () => ({})
    }
  },
  methods: {
    detailPage(){
        this.$emit('go')
    },
       
  },
}

</script>
<style lang='scss' scoped>
@import "@/assets/css/mixin";

.cardW{
    width: 560px;
    height: 160px;
    background: #FEFEFE;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    box-sizing: border-box;
    cursor: pointer;
    .el-icon-arrow-right{
        font-size: 20px;
        color: rgb(131, 131, 131);
        font-weight: bold;
        margin-left: 7px;
    }
    .cardLeft{
        width: 130px;
        height: 130px;
        background: #FEFEFE;
        border-radius: 5px;
        overflow: hidden;
        .el-image{
            width: 100%;
            height: 100%;
        }
        .cardLeft_img{
            width: 100%;
            height: 100%;
           object-fit: cover;  

        }

    }
    ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
    }
    .cardRight{
        margin-left: 20px;
        height: fit-content;
        flex: 1;
        width: 100%;
        &_title{
            color: rgb(37, 37, 37);
            margin: 3px 0;
            text-align: left;
            @include clamp(1);
            @include no-wrap();
            width: 360px;

         }
        &_subN_title{
            color: #333333;
            font-size: 20px;
            font-weight: bold;
            
        }
        &_subT1{
             display: flex;
             align-items: center;
             justify-content: flex-start;
             margin-top: 3px;
             width: 100%;
             overflow-x:scroll;
             color: white;
             }

        &_subT{
             display: flex;
             align-items: center;
             justify-content: flex-start;
             margin-top: 3px;
             width: 100%;
             overflow-x:scroll;
             color: white;
             &_tag1{
                 background: #D40D0D;
                 border-radius: 13px;
                 font-size: 14px;
                 padding: 3px 12px;
                 margin-right: 8px;
                 flex: none;
             }
            
        }

         &_sub1{
             display: flex;
             align-items: center;
             justify-content: space-between;
             margin-top: 3px;
             color: #999999;
                font-size: 16px;
              &_tag2{
                color: #333;
                 .redAcite{
                     color: #DF9C41;
                     font-weight: bold;
                 }
             }
             &.left {
                justify-content: start;
             }

             .cardRight-land-tag {
                color: #06BDE1;
                display: inline-block;
                font-weight: 500;
                height: 48px;
                line-height: 48px;
                max-width: 360px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 18px;
                margin-right: 24px;
                text-align: center;

                &.type {
                    height: 28px;
                    line-height: 28px;
                    color: #898989;
                    background: rgba(201, 208, 209, 0.13);
                    border: 1px solid rgba(194, 194, 194, 0.36);
                    border-radius: 3px;
                    font-size: 12px;
                    padding: 0 10px;
                }
             }
            
        }



    }
}
</style>